<template>
  <div class="row">
    <div class="col-12 text-center py-5 h3">404 Page Not Found</div>
    <div class="col-12 text-center">
      <router-link to="/">Back to Home</router-link>
    </div>
  </div>
  <!-- <div class="fullscreen bg-blue text-white text-center q-pa-md flex flex-center">
    <div>
      <div style="font-size: 30vh">
        404
      </div>

      <div class="text-h2" style="opacity:.4">
        Oops. Nothing here...
      </div>

      <q-btn
        class="q-mt-xl"
        color="white"
        text-color="blue"
        unelevated
        to="/"
        label="Go Home"
        no-caps
      />
    </div>
  </div> -->
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style>

</style>